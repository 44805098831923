export const white = "#fff"
export const offWhite = "#F8F8F8"
export const gray0 = "#EEEEEE"
export const gray1 = "#E1E1E1"
export const gray2 = "#B8B8B8"
export const gray3 = "#757575"
export const gray4 = "#272727"
export const black = "#000"
export const red = "#EB1414"
export const blue = "#0B7F99"
export const yellow = "#FFEC54"
export const bluish = '#0088ff'